import { autoPlacement, autoUpdate, offset, Placement, size, useFloating } from '@floating-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Combobox as HeadlessCombobox,
    ComboboxButton as HeadlessComboboxButton,
    ComboboxInput as HeadlessComboboxInput,
    Label as HeadlessComboboxLabel,
    ComboboxOption as HeadlessComboboxOption,
    ComboboxOptions as HeadlessComboboxOptions,
} from '@headlessui/react';
import { ClassValue, clsx } from 'clsx';
import { debounce } from 'lodash';
import { HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { RefCallBack } from 'react-hook-form';

export interface ComboboxOption<TId extends string | number, TValue> {
    id?: TId;
    icon?: ReactNode;
    value: TValue;
    disabled?: boolean;
    tag?: string;
    displayContent?: ReactNode;
}

export interface ComboboxProps<TId extends string | number, TValue> {
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    className?: ClassValue;
    action?: {
        placeholder?: ReactNode;
        trigger: () => void;
        dontCloseAfterClick?: boolean;
        actionInputRef?: React.RefObject<HTMLInputElement>;
    };
    options: ComboboxOption<TId, TValue>[];
    name?: string;
    value?: ComboboxOption<TId, TValue> | null;
    defaultValue?: ComboboxOption<TId, TValue> | null;
    setValue: (value?: ComboboxOption<TId, TValue> | null) => void;
    onSearch: (search: string) => void;
    formatOption?: ({
        value,
        selected,
        active,
        options,
    }: {
        value: ComboboxOption<TId, TValue>;
        selected?: boolean;
        active?: boolean;
        options: ComboboxOption<TId, TValue>[];
    }) => ReactNode;
    formatSelectedOption?: ({
        value,
        selected,
        active,
        options,
    }: {
        value: ComboboxOption<TId, TValue>;
        selected?: boolean;
        active?: boolean;
        options: ComboboxOption<TId, TValue>[];
    }) => string;
    disabled?: boolean;
    required?: boolean;
    label?: ReactNode; // Prefer passing string for keeping styling
    placeholder?: string;
    errorMessage?: string;
    icon?: IconDefinition;
    showChevron?: boolean;
    fieldRef?: RefCallBack;
    allowedPlacements?: Placement[];
    enableDropSelection?: boolean;
    autoAdaptOptionWidth?: boolean;
    onOpen?: (isOpen: boolean) => void;
}

type ValueOrRecord<T> = T | { [x: string]: ValueOrRecord<T> };

const defaultDisplayContent = <TId extends string | number, TValue>(
    option: ComboboxOption<TId, TValue>,
    idx: number,
    options: ComboboxOption<TId, TValue>[],
    action:
        | {
              placeholder?: ReactNode;
              trigger: () => void;
              dontCloseAfterClick?: boolean;
              actionInputRef?: React.RefObject<HTMLInputElement>;
          }
        | undefined,
    formatOption: ({
        value,
        selected,
        active,
        options,
    }: {
        value: ComboboxOption<TId, TValue>;
        selected?: boolean;
        active?: boolean;
        options: ComboboxOption<TId, TValue>[];
    }) => ReactNode
): ReactNode => (
    <HeadlessComboboxOption
        key={`opt-${idx}`}
        value={option}
        disabled={option.disabled}
        className={({ active }) =>
            clsx('flex px-4 py-2.5 hover:bg-gray-100 active:bg-gray-100', {
                'text-slate-400 cursor-not-allowed': option.disabled,
                'cursor-pointer': !option.disabled,
                'bg-gray-100': active,
                'rounded-t-md': idx === 0 && !action,
                'rounded-b-md': idx === options.length - 1,
            })
        }
    >
        {option.icon && <span className='my-auto mr-3'>{option.icon}</span>}
        {formatOption({ value: option, options })}
    </HeadlessComboboxOption>
);

export const Combobox = <TId extends string | number, TValue extends ValueOrRecord<string | number | boolean>>({
    action,
    options,
    name,
    value = null, // Avoid undefined value for controlled component
    defaultValue,
    setValue,
    onSearch,
    formatOption = ({ value }) => <>{value?.value}</>,
    formatSelectedOption = ({ value }) => value?.value as string,
    placeholder = '',
    disabled = false,
    required = false,
    label,
    errorMessage,
    className,
    dataTestid,
    htmlAttributes,
    icon,
    showChevron = true,
    fieldRef,
    allowedPlacements = ['bottom-start', 'top-start'],
    enableDropSelection = false,
    autoAdaptOptionWidth = true,
    onOpen = () => {},
}: ComboboxProps<TId, TValue>) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { refs, floatingStyles } = useFloating({
        middleware: [
            autoPlacement({
                allowedPlacements,
            }),
            offset(2),
            size({
                apply({ availableHeight, elements }) {
                    elements.floating.style.maxHeight = availableHeight < 384 ? `${availableHeight}px` : '384px';
                    if (autoAdaptOptionWidth) {
                        elements.floating.style.width = `${elements.reference.getBoundingClientRect().width}px`;
                    }
                },
            }),
        ],
        placement: 'bottom-start',
        strategy: 'fixed',
        whileElementsMounted: autoUpdate,
    });
    const wrapperRef = useRef<HTMLDivElement>(null);
    const optionsRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, isOpen]);

    useEffect(() => {
        onOpen(isOpen);
    }, [isOpen, onOpen]);

    // The classical useEffect debounce doesn't work well with async functions
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedOnSearch = useCallback(
        debounce((value) => onSearch(value), 300),
        [onSearch]
    );

    const processedOptions = (options || []).map((option, idx) => ({
        ...option,
        displayContent: option.displayContent || defaultDisplayContent(option, idx, options, action, formatOption),
    }));

    return (
        <div
            className={clsx(className, {
                'cursor-not-allowed': disabled,
            })}
            {...htmlAttributes}
            ref={wrapperRef}
        >
            <HeadlessCombobox
                name={name}
                as='div'
                immediate
                data-testid={dataTestid}
                value={value}
                onChange={(value) => {
                    if (typeof value === 'string' && value === '_action') {
                        if (typeof action?.trigger === 'function') {
                            action.trigger();
                        }
                        if (action?.dontCloseAfterClick) {
                            setIsOpen(true);
                        } else {
                            setIsOpen(false);
                        }
                        return;
                    } else if (value) {
                        setValue(value);
                        setIsOpen(false);
                    }
                }}
                defaultValue={defaultValue}
                disabled={disabled}
            >
                {label && (
                    <HeadlessComboboxLabel
                        className={clsx('mb-2 block text-xs font-medium text-gray-800', { 'ml-9': icon })}
                    >
                        {label} {required && '*'}
                    </HeadlessComboboxLabel>
                )}
                <div
                    role='searchbox'
                    className='flex w-full min-w-0 items-center gap-3'
                >
                    {icon && (
                        <FontAwesomeIcon
                            className={clsx('size-[24px] shrink-0 text-gray-500', {})}
                            icon={icon}
                        />
                    )}
                    <div
                        role='searchbox'
                        className={clsx([
                            'relative flex h-10 w-full items-center p-2',
                            'rounded-md ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset',
                            {
                                'bg-gray-100 text-gray-400': disabled,
                                'bg-white text-gray-500': !disabled,
                                'ring-red-300 focus-within:ring-red-300': errorMessage,
                                'ring-gray-300 focus-within:ring-blue-300': !errorMessage,
                            },
                        ])}
                        ref={refs.setReference}
                    >
                        <HeadlessComboboxInput
                            className={clsx(
                                'mr-2 w-full min-w-0 grow border-0 bg-transparent p-0 text-sm text-gray-800 focus:ring-0',
                                {
                                    'cursor-not-allowed': disabled,
                                }
                            )}
                            onChange={(event) => {
                                delayedOnSearch(event.target.value);
                            }}
                            onFocus={(event) => {
                                if (!event.target.value) onSearch('');
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onBlur={(e) => {
                                const { relatedTarget } = e;
                                if (action && relatedTarget !== action.actionInputRef?.current) {
                                    setIsOpen(false);
                                }
                            }}
                            onClick={(event) => {
                                if (buttonRef?.current) {
                                    buttonRef.current.click();
                                    setIsOpen(!isOpen);
                                    event.stopPropagation();
                                    event.preventDefault();
                                }
                            }}
                            displayValue={(value: ComboboxOption<TId, TValue>) => {
                                if (typeof formatSelectedOption === 'function') {
                                    return formatSelectedOption({ value, options }) as string;
                                }
                                return value?.value as string;
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'ArrowDown' && wrapperRef.current?.contains(document.activeElement)) {
                                    event.stopPropagation();
                                    if (!isOpen) {
                                        setIsOpen(true);
                                    }
                                }
                                if (
                                    event.key === 'Escape' &&
                                    wrapperRef.current?.contains(document.activeElement) &&
                                    isOpen
                                ) {
                                    event.stopPropagation();
                                    setIsOpen(false);
                                }
                            }}
                            placeholder={placeholder}
                            ref={fieldRef}
                            autoComplete='off'
                            aria-disabled={disabled}
                        />
                        <HeadlessComboboxButton
                            as='div'
                            ref={buttonRef}
                        >
                            {showChevron && !disabled ? (
                                <button
                                    type='button'
                                    className='ml-auto flex items-center border-l border-solid border-gray-300 text-sm'
                                    onClick={(event) => {
                                        if (!disabled) {
                                            setIsOpen(!isOpen);
                                        }
                                        event.stopPropagation();
                                        if (enableDropSelection && value) {
                                            setValue(null);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={enableDropSelection && value?.id ? faClose : faChevronDown}
                                        className='p-1 pl-3'
                                    />
                                </button>
                            ) : null}
                        </HeadlessComboboxButton>
                    </div>
                    {(action || options.length > 0) && isOpen && (
                        <div
                            className={clsx(
                                'z-dropdown overflow-y-auto rounded-md border border-gray-300 bg-white text-sm text-gray-800 shadow-lg',
                                {
                                    'min-w-64': !autoAdaptOptionWidth,
                                    'w-full': autoAdaptOptionWidth,
                                }
                            )}
                            ref={refs.setFloating}
                            style={{ ...floatingStyles }}
                        >
                            <HeadlessComboboxOptions static>
                                {action && (
                                    <HeadlessComboboxOption
                                        value='_action'
                                        className={({ active }) =>
                                            clsx(
                                                `cursor-pointer  ${
                                                    action?.dontCloseAfterClick
                                                        ? ''
                                                        : 'border-b border-solid border-gray-300 p-2 hover:bg-gray-100 active:bg-gray-100'
                                                }`,
                                                {
                                                    'bg-gray-100 rounded-t-md': active && !action?.dontCloseAfterClick,
                                                }
                                            )
                                        }
                                    >
                                        {action?.placeholder}
                                    </HeadlessComboboxOption>
                                )}
                                {processedOptions.map((option, idx) => (
                                    <div
                                        key={`opt-${idx}`}
                                        ref={(el) => (optionsRefs.current[idx] = el as HTMLDivElement)}
                                    >
                                        {option.displayContent}
                                    </div>
                                ))}
                            </HeadlessComboboxOptions>
                        </div>
                    )}
                </div>
            </HeadlessCombobox>

            {errorMessage && (
                <p
                    className={clsx('mt-2 text-xs font-medium text-red-500', { 'ml-9': !!icon })}
                    data-testid={dataTestid && `${dataTestid}-error`}
                >
                    {errorMessage}
                </p>
            )}
        </div>
    );
};
