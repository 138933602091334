import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { gql, useQuery } from '@apollo/client';
import { LoadableBreadcrumbItem } from '@spacefill/shared/src/components/breadcrumb';
import { Breadcrumb } from '@spacefill/uikit/src/components/Breadcrumb';
import { isEmpty } from 'lodash';
import { getDateFnsCurrentLocale } from '@spacefill/shared/src/i18n/I18n';
import { getCountryNameFromCode } from '@spacefill/shared/src/utils/CountryUtils';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';

import { NotificationsSidePanel } from './Notifications/NotificationsSidePanel';

/* eslint-disable react-hooks/rules-of-hooks */
const routes = [
    {
        path: '/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Home');
        },
    },
    {
        path: '/settings/users/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Users');
        },
    },
    {
        path: '/warehouses/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Warehouses');
        },
    },
    {
        path: '/warehouses/:warehouseId',
        breadcrumb: ({ match }) => {
            const { user } = useUserContext();
            const warehouse = user?.customerWarehouseConfigurations?.find(
                (cwc) => cwc.warehouseId === match.params.warehouseId
            )?.warehouse;

            if (!warehouse) {
                return match.params.warehouseId;
            }

            return warehouse.label;
        },
    },
    {
        path: '/warehouses/:warehouseId/edit',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Edit');
        },
    },
    {
        path: '/company-profile/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Company profile');
        },
    },
    {
        path: '/profile/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('User profile');
        },
    },
    {
        path: '/logistic-management',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Logistic management');
        },
    },
    {
        path: '/logistic-management/orders/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Orders');
        },
    },
    {
        path: '/logistic-management/inventory/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Inventory');
        },
    },
    {
        path: '/logistic-management/inventory/items/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('References');
        },
    },
    {
        path: '/logistic-management/inventory/items/new',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('New');
        },
    },
    {
        path: '/logistic-management/inventory/items/:masterItemId/edit',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Edit');
        },
    },
    {
        path: '/logistic-management/inventory/items/:masterItemId/',
        breadcrumb: ({ match }) => {
            const { t } = useTranslation();
            const { loading, error, data } = useQuery(
                gql`
                    query getLogisticManagementMasterItem($id: UUID!) {
                        logisticManagementMasterItem(id: $id) {
                            itemReference
                        }
                    }
                `,
                {
                    variables: {
                        id: match.params.masterItemId,
                    },
                }
            );
            if (loading || error || !data?.logisticManagementMasterItem) {
                return '';
            }

            const texts = {
                loaded: data?.logisticManagementMasterItem?.itemReference,
                loading: t('Not specified'),
            };
            return LoadableBreadcrumbItem(texts, 'itemReference');
        },
    },
    {
        path: '/logistic-management/inventory/stock-situation/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Stock situation');
        },
    },
    {
        path: '/logistic-management/inventory/stock-situation/references',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('By reference');
        },
    },
    {
        path: '/logistic-management/inventory/stock-situation/references/forecasted',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Forecasted');
        },
    },
    {
        path: '/logistic-management/inventory/stock-situation/references/accurate',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Accurate stock situation');
        },
    },
    {
        path: '/logistic-management/inventory/stock-situation/unique-identifier',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('By unique identifier');
        },
    },
    {
        path: '/logistic-management/orders/:orderId/order-adjustment/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Order adjustment');
        },
    },
    {
        path: '/logistic-management/inventory/:warehouseId/stock-adjustment/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Stock adjustment');
        },
    },
    {
        path: '/logistic-management/warehouses/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Warehouses');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/',
        breadcrumb: ({ match }) => {
            const { user } = useUserContext();
            const warehouse = user?.customerWarehouseConfigurations?.find(
                (cwc) => cwc.warehouseId === match.params.warehouseId
            )?.warehouse;

            if (!warehouse) {
                return match.params.warehouseId;
            }

            return warehouse.label;
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Orders');
        },
    },
    {
        path: '/logistic-management/unconfirmed-orders',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Orders to review');
        },
    },
    {
        path: '/logistic-management/unconfirmed-orders/:emailId/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Create Order');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders/new-entry',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('New entry');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders/new-exit',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('New exit');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/',
        breadcrumb: ({ match }) => {
            const { t } = useTranslation();
            const { loading, error, data } = useQuery(
                gql`
                    query getLogisticManagementOrderIid($id: UUID!) {
                        logisticManagementOrder(id: $id) {
                            iid
                        }
                    }
                `,
                {
                    variables: {
                        id: match.params.orderId,
                    },
                }
            );
            if (loading || error || !data?.logisticManagementOrder?.iid) {
                return <>{match.params.orderId}</>;
            }

            const texts = {
                loaded: data?.logisticManagementOrder?.iid,
                loading: t('Not specified'),
            };
            return LoadableBreadcrumbItem(texts, 'orderId');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit-entry',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Edit');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit-exit',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Edit');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/inventory/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Inventory');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/new-adjustment',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Stock adjustment');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Stock situation');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('By reference');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/forecasted',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Forecasted');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/last-snapshot',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('WMS Stock');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/order-adjustment',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Order adjustment');
        },
    },
    {
        path: '/logistic-management/incidents/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Incidents');
        },
    },
    {
        path: '/logistic-management/documents/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Documents');
        },
    },
    {
        path: '/settings/storages',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Warehouses');
        },
    },
    {
        path: '/settings/warehouses',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Warehouses');
        },
    },
    {
        path: '/settings/address-book',
        breadcrumb() {
            const { t } = useTranslation();
            return t('Address book');
        },
    },
    {
        path: '/settings/address-book/new',
        breadcrumb() {
            const { t } = useTranslation();
            return t('New');
        },
    },
    {
        path: '/settings/address-book/:customerAddressId',
        breadcrumb({ match }) {
            const { i18n } = useTranslation();
            const { loading, error, data } = useQuery(
                gql`
                    query GetCustomerAddressById($customerAddressId: UUID!) {
                        customerAddresses(filter: { id: { equalTo: $customerAddressId } }) {
                            nodes {
                                address {
                                    addressLine1
                                    city
                                    zipCode
                                    country
                                    countryCode
                                    details
                                }
                            }
                        }
                    }
                `,
                {
                    variables: {
                        customerAddressId: match.params.customerAddressId,
                    },
                }
            );

            // formatting address depending on data
            const formatAddress = (addressObject) => {
                if (!addressObject || isEmpty(addressObject)) return match.params.customerAddressId;

                let formattedAddress = addressObject?.addressLine1 ?? '';

                if (addressObject?.addressCity) {
                    formattedAddress = formattedAddress
                        ? `${formattedAddress}, ${addressObject?.addressCity}`
                        : addressObject?.addressCity;
                }
                if (addressObject?.addressZip) {
                    formattedAddress = formattedAddress
                        ? `${formattedAddress}, ${addressObject?.addressZip}`
                        : addressObject?.addressZip;
                }
                if (addressObject?.addressCountry) {
                    formattedAddress = formattedAddress
                        ? `${formattedAddress}, ${addressObject?.addressCountry}`
                        : addressObject?.addressCountry;
                }

                return formattedAddress !== '' ? formattedAddress : match.params.customerAddressId;
            };

            if (loading || error) {
                return match.params.customerAddressId;
            }
            return formatAddress({
                addressLine1: data?.customerAddresses?.nodes[0]?.address?.addressLine1,
                addressZip: data?.customerAddresses?.nodes[0]?.address?.zipCode,
                addressCity: data?.customerAddresses?.nodes[0]?.address?.city,
                addressCountry: getCountryNameFromCode(
                    data?.customerAddresses?.nodes[0]?.address?.countryCode,
                    i18n.language,
                    data?.customerAddresses?.nodes[0]?.address?.country
                ),
            });
        },
    },
    {
        path: '/settings/address-book/:customerAddressId/edit',
        breadcrumb() {
            const { t } = useTranslation();
            return t('Edit');
        },
    },
    {
        path: '/logistic-management/analytics/',
        breadcrumb() {
            const { t } = useTranslation();
            return t('Analytics');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/billing/',
        breadcrumb() {
            const { t } = useTranslation();
            return t('Billing');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/',
        breadcrumb: ({ match }) => {
            const { t } = useTranslation();
            const { loading, error, data } = useQuery(
                gql`
                    query getCustomerWarehouseConfigurationCustomer($id: UUID!) {
                        customerWarehouseConfiguration(id: $id) {
                            customer {
                                company
                            }
                        }
                    }
                `,
                {
                    variables: {
                        id: match.params.customerWarehouseConfigurationId,
                    },
                }
            );
            if (loading || error || !data?.customerWarehouseConfiguration?.customer?.company) {
                return <>{match.params.customerWarehouseConfigurationId}</>;
            }

            const texts = {
                loaded: data?.customerWarehouseConfiguration?.customer?.company,
                loading: t('Not specified'),
            };
            return LoadableBreadcrumbItem(texts, 'customerWarehouseConfigurationId');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/invoices/',
        breadcrumb() {
            const { t } = useTranslation();
            return t('Invoices');
        },
    },
    {
        path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/invoices/:invoiceId/',
        breadcrumb: ({ match }) => {
            const { t, i18n } = useTranslation();
            const { loading, error, data } = useQuery(
                gql`
                    query getBillingInvoiceCustomer($id: UUID!) {
                        billingInvoice(id: $id) {
                            date
                        }
                    }
                `,
                {
                    variables: {
                        id: match.params.invoiceId,
                    },
                }
            );
            if (loading || error || !data?.billingInvoice?.date) {
                return <>{match.params.customerWarehouseConfigurationId}</>;
            }
            const formatedDate =
                format(parseISO(data?.billingInvoice?.date), 'MMMM yyyy', {
                    locale: getDateFnsCurrentLocale(i18n.language.toLowerCase()),
                }) || t('Not specified');

            return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
        },
    },
    {
        path: '/settings/custom-fields/',
        breadcrumb: () => {
            const { t } = useTranslation();
            return t('Custom fields');
        },
    },
];
/* eslint-enable react-hooks/rules-of-hooks */

const SpacefillBreadcrumb = () => {
    const breadcrumbs = useBreadcrumbs(routes);

    return (
        <div className='flex items-center justify-between bg-gray-100 px-6 py-2 h-12'>
            <Breadcrumb breadcrumbs={breadcrumbs} />

            <NotificationsSidePanel />
        </div>
    );
};

export { SpacefillBreadcrumb };
