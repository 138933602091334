import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems, MenuItemsProps, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface DropdownItemProps {
    disabled?: boolean;
    divider?: boolean;
    content: ReactNode;
    onClick?: () => void;
    href?: string;
    htmlAttributes?: HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>;
    dataTestid?: string;
}

export interface DropdownProps {
    variant?: 'primary' | 'secondary' | 'outlined';
    small?: boolean;
    dataTestid?: string;
    buttonText?: string;
    iconStart?: ReactNode;
    iconEnd?: ReactNode;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    classNames?: string[];
    options: DropdownItemProps[];
    anchor?: MenuItemsProps['anchor'];
}

const getOptionClasses = (focus: boolean, disabled: boolean, divider?: boolean) =>
    clsx(
        'flex justify-between whitespace-nowrap px-4 py-2 text-left text-sm leading-5 text-gray-400 drop-shadow-none first:rounded-t-[inherit] last:rounded-b-[inherit]',
        {
            'bg-gray-100': focus && !disabled,
            'text-gray-800 cursor-pointer': !disabled,
            'cursor-not-allowed': disabled,
            'border-b border-gray-300 last:border-b-0': divider,
        }
    );

export const Dropdown = ({
    variant = 'outlined',
    buttonText,
    iconStart,
    iconEnd,
    small = false,
    dataTestid,
    htmlAttributes,
    classNames = [],
    options = [],
    anchor = 'bottom start',
}: DropdownProps) => (
    <div
        className={clsx([...classNames])}
        data-testid={dataTestid}
        {...htmlAttributes}
    >
        <Menu>
            <MenuButton
                className={clsx('flex items-center gap-2 rounded-md text-sm', small ? 'px-2 py-1.5' : 'px-4 py-2', {
                    'bg-lime-300 text-black hover:bg-lime-400 active:bg-lime-500 focus:outline-focus-primary disabled:bg-lime-100':
                        variant === 'primary',
                    'bg-gray-200 hover:bg-gray-300 active:bg-gray-400 focus:outline-focus-primary text-black disabled:bg-gray-100':
                        variant === 'secondary',
                    'bg-white hover:bg-gray-100 active:bg-gray-200 focus:outline-focus-secondary text-black border border-solid border-gray-300 disabled:bg-gray-100':
                        variant === 'outlined',
                    hidden: options.every((option) => option.content === false),
                })}
            >
                {iconStart}
                {buttonText && <span>{buttonText}</span>}
                {iconEnd ?? <FontAwesomeIcon icon={faChevronDown} />}
            </MenuButton>
            <Transition
                enter='transition duration-200 ease-out'
                enterFrom='transform scale-y-95 opacity-0'
                enterTo='transform scale-y-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-y-100 opacity-100'
                leaveTo='transform scale-y-95 opacity-0'
            >
                <MenuItems
                    anchor={anchor}
                    className='z-dropdown flex w-auto flex-col rounded-md border border-solid border-gray-300 bg-white text-sm shadow-lg [--anchor-gap:8px]'
                >
                    {options.map(
                        ({ disabled, dataTestid, htmlAttributes, onClick, content, divider, href }, idx) =>
                            content && (
                                <MenuItem
                                    key={idx}
                                    disabled={disabled}
                                >
                                    {({ focus, disabled }) =>
                                        href ? (
                                            <Link
                                                data-testid={dataTestid}
                                                to={href}
                                                onClick={onClick}
                                                {...htmlAttributes}
                                                className={getOptionClasses(focus, disabled, divider)}
                                            >
                                                {content}
                                            </Link>
                                        ) : (
                                            <button
                                                data-testid={dataTestid}
                                                onClick={onClick}
                                                {...htmlAttributes}
                                                className={getOptionClasses(focus, disabled, divider)}
                                            >
                                                {content}
                                            </button>
                                        )
                                    }
                                </MenuItem>
                            )
                    )}
                </MenuItems>
            </Transition>
        </Menu>
    </div>
);
