import { Fragment, ReactNode, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingBox, faWarehouseFull } from '@fortawesome/pro-light-svg-icons';
import { arrow, offset, safePolygon, useFloating, useHover, useInteractions } from '@floating-ui/react';

import { NavItem, NavItemProps } from './NavItem';
import logoSpacefill from './logo-spacefill.svg';

export interface UserInfoProps {
    userName: string;
    company: string;
    role?: string;
}

export type NavItemConfig = {
    subItems?: NavItemProps[];
} & NavItemProps;

export interface SidebarProps {
    mainNavigation?: NavItemConfig[];
    supportNavigation?: NavItemConfig[];
    bottomNavigation?: NavItemConfig[];
    profileTopNavigation?: NavItemConfig[];
    profileNavigation?: NavItemConfig[];
    userInfo: UserInfoProps;
    showUserSwitcher?: ReactNode;
    logo?: string | null;
    dataTestid?: string;
}

export const Sidebar = ({
    mainNavigation = [],
    bottomNavigation = [],
    profileNavigation = [],
    profileTopNavigation = [],
    showUserSwitcher = null,
    userInfo,
    logo = null,
    dataTestid,
}: SidebarProps) => (
    <nav
        role='navigation'
        className='flex h-full flex-col bg-zinc-900'
    >
        <div
            className='overflow-y-auto bg-zinc-900'
            // Force dark mode on sidebar to have a black scrollbar
            style={{ colorScheme: 'dark' }}
        >
            {showUserSwitcher ? (
                showUserSwitcher
            ) : (
                <img
                    className='py-6 pl-4 pr-16'
                    alt='logo'
                    src={logo ?? logoSpacefill}
                />
            )}
            <ul className='flex flex-col gap-1 bg-zinc-900 px-2'>
                {mainNavigation.map((navItem, idx) => (
                    <NavItem
                        key={`nav-item-${idx}`}
                        title={navItem.title}
                        icon={navItem.icon}
                        hasRoundedIcon={navItem.hasRoundedIcon}
                        bottomDivider={navItem.bottomDivider}
                        logoUrl={navItem.logoUrl ?? ''}
                        countValue={navItem.countValue}
                        isProFeature={navItem.isProFeature}
                        to={navItem.to}
                        isActive={navItem.isActive}
                        href={navItem.href}
                        target={navItem.target}
                        onClick={navItem.onClick}
                        dataTestid={navItem.dataTestid}
                    >
                        {navItem.subItems?.length && (
                            <ul data-testid='sidebar-navitem'>
                                {navItem.subItems.map((subItem: NavItemConfig, subIdx) => (
                                    <NavItem
                                        key={`subnav-item-${idx}-subitem-${subIdx}`}
                                        title={subItem.title}
                                        className='mb-1'
                                        icon={subItem.icon}
                                        hasRoundedIcon={subItem.hasRoundedIcon}
                                        bottomDivider={subItem.bottomDivider}
                                        logoUrl={subItem.logoUrl}
                                        isProFeature={subItem.isProFeature}
                                        countValue={subItem.countValue}
                                        isActive={subItem.isActive}
                                        to={subItem.to}
                                        href={subItem.href}
                                        target={subItem.target}
                                        onClick={subItem.onClick}
                                        dataTestid={subItem.dataTestid}
                                    />
                                ))}
                            </ul>
                        )}
                    </NavItem>
                ))}
            </ul>
        </div>

        <div className='mt-auto border-t border-solid border-t-zinc-600 pt-2'>
            <ul className='bg-zinc-900 px-2'>
                {bottomNavigation.map((navItem, idx) => (
                    <NavItem
                        key={`nav-item-${idx}`}
                        title={navItem.title}
                        logoUrl={navItem.logoUrl ?? ''}
                        icon={navItem.icon}
                        hasRoundedIcon={navItem.hasRoundedIcon}
                        bottomDivider={navItem.bottomDivider}
                        countValue={navItem.countValue}
                        isProFeature={navItem.isProFeature}
                        isActive={navItem.isActive}
                        to={navItem.to}
                        href={navItem.href}
                        target={navItem.target}
                        onClick={navItem.onClick}
                        dataTestid={dataTestid && `${dataTestid}-title`}
                    >
                        {navItem.subItems?.length && (
                            <ul
                                data-testid='sidebar-navitem'
                                className={`origin-top rounded-md bg-zinc-800 p-2 transition`}
                            >
                                {navItem.subItems.map((subItem: NavItemProps, subIdx) => (
                                    <NavItem
                                        key={`subnav-item-${idx}-subitem-${subIdx}`}
                                        title={subItem.title}
                                        className='mb-1'
                                        icon={subItem.icon}
                                        hasRoundedIcon={subItem.hasRoundedIcon}
                                        bottomDivider={navItem.bottomDivider}
                                        isProFeature={subItem.isProFeature}
                                        countValue={subItem.countValue}
                                        isActive={subItem.isActive}
                                        to={subItem.to}
                                        href={subItem.href}
                                        target={subItem.target}
                                        onClick={subItem.onClick}
                                        dataTestid={subItem.dataTestid}
                                    />
                                ))}
                            </ul>
                        )}
                    </NavItem>
                ))}
            </ul>

            <div className='mt-auto bg-zinc-900'>
                <ProfileNav
                    profileTopNavigation={profileTopNavigation}
                    profileNavigation={profileNavigation}
                    userInfo={userInfo}
                />
            </div>
        </div>
    </nav>
);

function ProfileNav({ profileTopNavigation = [], profileNavigation = [], userInfo }: SidebarProps) {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'right-end',
        middleware: [
            offset({ mainAxis: 6 }),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const hover = useHover(context, {
        handleClose: safePolygon({
            buffer: 1,
        }),
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <div
            className=''
            ref={refs.setReference}
            {...getReferenceProps()}
        >
            <div
                className={clsx(
                    'm-2 flex items-center gap-4 rounded-md p-2 text-sm font-medium text-white hover:cursor-pointer hover:font-bold',
                    {
                        'bg-zinc-700': isOpen,
                        'bg-zinc-800': !isOpen,
                    }
                )}
            >
                <FontAwesomeIcon
                    icon={userInfo.role === 'SHIPPER' ? faHandHoldingBox : faWarehouseFull}
                    className='w-3.5 rounded-full bg-zinc-700 p-2 text-white'
                />
                <div
                    className='truncate'
                    data-testid='user-info'
                >
                    <p data-testid='name'>{userInfo.userName}</p>
                    <p
                        className='text-xs text-zinc-400'
                        data-testid='company'
                    >
                        {userInfo.company}
                    </p>
                </div>
            </div>

            {isOpen && (
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                    className={`rounded-md bg-zinc-800 p-2`}
                >
                    {profileTopNavigation.length > 0 && (
                        <Fragment>
                            <ul>
                                {profileTopNavigation.map((props, idx) => (
                                    <NavItem
                                        {...props}
                                        key={`profile-top-item-${idx}`}
                                    />
                                ))}
                            </ul>
                            <hr className='my-1 border-zinc-600' />
                        </Fragment>
                    )}
                    <ul>
                        {profileNavigation.map((props, idx) => (
                            <NavItem
                                {...props}
                                key={`profile-item-${idx}`}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
