import React from 'react';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { toast } from 'react-hot-toast';
import TagManager from 'react-gtm-module';
import { t } from 'i18next';
import { pingGraphQLServer } from '@spacefill/shared/src/utils/Apollo';
import '@spacefill/shared/src/utils/YupMethods';

import '@spacefill/uikit/src/index.css';
import { App } from './App';

const googletagManagerId =
    window?._spacefill_env_?.googleTagManagerId || import.meta.env.VITE_EXTERNAL_CONSOLE_GOOGLE_TAG_MANAGER_ID;
const googleTagManagerAuth =
    window?._spacefill_env_?.googleTagManagerAuth || import.meta.env.VITE_EXTERNAL_CONSOLE_GOOGLE_TAG_MANAGER_AUTH;
const googletagManagerEnv =
    window?._spacefill_env_?.googleTagManagerEnv || import.meta.env.VITE_EXTERNAL_CONSOLE_GOOGLE_TAG_MANAGER_ENV;
const datadogApplicationId =
    window?._spacefill_env_?.datadogApplicationId || import.meta.env?.VITE_EXTERNAL_CONSOLE_DATADOG_APPLICATION_ID;
const datadogClientToken =
    window?._spacefill_env_?.datadogClientToken || import.meta.env?.VITE_EXTERNAL_CONSOLE_DATADOG_CLIENT_TOKEN;
const datadogVersion =
    window?._spacefill_env_?.datadogVersion || import.meta.env?.VITE_EXTERNAL_CONSOLE_DATADOG_VERSION;

if (datadogApplicationId) {
    datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'external-console',

        version: datadogVersion,
        allowedTracingUrls: [(url) => url.includes('spacefill.fr')],
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}

if (googletagManagerId && googletagManagerId !== '') {
    TagManager.initialize({
        gtmId: googletagManagerId,
        auth: googleTagManagerAuth,
        preview: googletagManagerEnv,
    });
}

const enableMockTesting = new URLSearchParams(window.location.search).get('enableMockTesting');
if (enableMockTesting) {
    localStorage.setItem('enableMockTesting', '1');
}

if (window.navigator.userAgent === 'puppeteer') {
    window.toast = toast;
}

pingGraphQLServer((error) => {
    toast.error(t('Internal error!'));
    console.error(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
