import React from 'react';
import { Trans } from 'react-i18next';
import { faComment, faDiamondExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { t } from 'i18next';

export const templates = {
    comment_added_to_order: {
        title() {
            return t('Comment');
        },
        icon() {
            return faComment;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='added a new comment on order <1>{{ orderReference }}</1>.'>
                    added a new comment on order <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#activities?commentId=${notification.templateParams.orderComment.id}`;
        },
    },
    mentioned_in_order_comment: {
        title() {
            return t('Comment');
        },
        icon() {
            return faComment;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='mentioned you in a new comment on order <1>{{ orderReference }}</1>.'>
                    mentioned you in a new comment on order <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#activities?commentId=${notification.templateParams.orderComment.id}`;
        },
    },
    comment_added_to_order_incident: {
        title() {
            return t('Comment');
        },
        icon() {
            return faComment;
        },
        content(notification = {}) {
            const incidentReference = notification.templateParams.incident.iid;
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='added a new comment on incident <1>{{ incidentReference }}</1> about order <3>{{ orderReference }}</3>.'>
                    added a new comment on incident <span className='font-semibold'>{{ incidentReference }}</span> about
                    order <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    mentioned_in_incident_comment: {
        title() {
            return t('Comment');
        },
        icon() {
            return faComment;
        },
        content(notification = {}) {
            const incidentReference = notification.templateParams.incident.iid;
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='mentioned you in a new comment on incident <2>{{ incidentReference }}</2> about order <5>{{ orderReference }}</5>.'>
                    mentioned you in a new comment on incident{' '}
                    <span className='font-semibold'>{{ incidentReference }}</span> about order{' '}
                    <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    order_incident_created: {
        title() {
            return t('Incident');
        },
        icon() {
            return faDiamondExclamation;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='created a new incident about order <1>{{ orderReference }}</1>.'>
                    created a new incident about order
                    <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    order_incident_closed: {
        title() {
            return t('Incident');
        },
        icon() {
            return faDiamondExclamation;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='closed an incident about the order <1>{{ orderReference }}</1>.'>
                    closed an incident about the order
                    <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    order_incident_canceled: {
        title() {
            return t('Incident');
        },
        icon() {
            return faDiamondExclamation;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='canceled an incident about the order <1>{{ orderReference }}</1>.'>
                    canceled an incident about the order
                    <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
};
